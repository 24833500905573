@import url(https://fonts.googleapis.com/css?family=Lato);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: "Lato";
}

/* Hide element on Desktop */
@media only screen and (min-width: 981px) {
  .hide-on-desktop {
    display: none !important;
  }

  .companyDetailsContainer {
    display: flex;
    flex-direction: row;
  }

  .chartContainer {
    margin-right: 10px;
  }
}

/* Hide element on Tablet/Phone */
@media only screen and (max-width: 980px) {
  .hide-on-mobile-tablet {
    display: none !important;
  }

  .companyDetailsContainer {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
  }
}

#desktopMenu {
  list-style: none;
  display: flex;
  flex-direction: row;
  flex: 1 1;
  justify-content: flex-end;
}

.menuLink {
  text-decoration: none;
  color: #0c3357;
}

.slide-in {
  transition: -webkit-transform 0.3s ease-in-out 0s;
  transition: transform 0.3s ease-in-out 0s;
  transition: transform 0.3s ease-in-out 0s, -webkit-transform 0.3s ease-in-out 0s;
  -webkit-transform: translate3d(0px, 0px, 0px);
          transform: translate3d(0px, 0px, 0px);
}

.slide-out {
  -webkit-transform: translate3d(-100%, 0px, 0px);
          transform: translate3d(-100%, 0px, 0px);
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.markdown {
  color: #0c3357;
}

.readMore {
  color: #0c3357;
  text-decoration: none;
  font-weight: bold;
}

.mobileUl {
  list-style: none;
  flex: 1 1;
  justify-content: flex-end;
}

.mobileUl li {
  padding: 0.5em;
}

